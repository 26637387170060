import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import icons from '../components/icons'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#7D6A5C',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },

  icons: {
    values: {
      gaming: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'gaming',
        },
      },
      car: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'car',
        },
      },
      outdoor: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'outdoor',
        },
      },
      reading: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'reading',
        },
      },
      flight: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'flight',
        },
      },
      bike: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'bike',
        },
      },
      train: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'train',
        },
      },
      swim: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'swim',
        },
      },
      vegetables_meat: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'vegetables_meat',
        },
      },
      vegetables: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'vegetables',
        },
      },
      xl_vegetables_meat: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'xl_vegetables_meat',
        },
      },
      meat: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'meat',
        },
      },
      house_candle: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'house_candle',
        },
      },
      house_light: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'house_light',
        },
      },
      house_open: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'house_open',
        },
      },
      house_lights: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'house_lights',
        },
      },
      glass: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'glass',
        },
      },
      bag: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'bag',
        },
      },
      carton: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'carton',
        },
      },
      plastic: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'plastic',
        },
      },
      water: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'water',
        },
      },
      coal: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'coal',
        },
      },
      solar_energy: {
        component: icons, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'solar_energy',
        },
      },
      wind: {
          component: icons, // you can use string here if component is registered globally
          props: { // pass props to your component if needed
            name: 'wind',
          },
        },
    },
  },
})







<template>
  <v-app>
    <!--
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-icon>mdi-earth</v-icon>
      </div>
    </v-app-bar>
    -->
    <v-main>
      <start/>
    </v-main>
  </v-app>
</template>

<script>
import start from './components/start';

export default {
  name: 'App',

  components: {
    start,
  },

  data: () => ({
    //
  }),
};
</script>

<template>
  <v-container fluid class="py-2">
    <v-row dense>
      <v-col v-for="result in results" :key="result.title" :cols="result.flex">
        <v-card>
          <v-img
            :src="result.images[currentThreshold].src"
            class="align-start"
            :aspect-ratio="10/8"
            :width="width"
            cover
          >
            <v-dialog
              max-width="800"
              transition="dialog-top-transition"
              overlay-opacity="0.56"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  top
                  right
                  absolute
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title
                    class="justify-center mb-9"
                    v-text="result.title"
                  ></v-card-title>
                  <v-card-text v-for="text in result.about" :key="text.sub">
                    <h3
                      class="mb-4"
                      v-text="text.sub"
                      v-if="text.sub !== undefined"
                    ></h3>
                    <p>
                      <span
                        v-text="text.p1"
                        v-if="text.p1 !== undefined"
                      ></span>
                      <span
                        class="font-weight-bold"
                        v-if="text.p2 !== undefined"
                        ><br />{{ text.p2 }}</span
                      >
                    </p>
                    <v-alert
                      v-if="text.p3 !== undefined"
                      color="primary"
                      dark
                      icon="mdi-thought-bubble"
                      border="left"
                      prominent
                      class="mt-9"
                    >
                      {{ text.p3 }}
                    </v-alert>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="primary"
                      @click="dialog.value = false"
                      x-large
                      rounded
                      text
                    >
                      Schließen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <v-card-title class="text-subtitle-1 font-weight-bold" v-text="result.title"></v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col class="d-flex align-center" cols="3">
        <v-select
          v-model="currentTimeTravel"
          :items="timeTravelList"
          solo
          rounded
          color="primary"
        ></v-select>
      </v-col>
      <v-col class="d-flex justify-center" cols="6">
        <v-icon size="50" v-if="trend === 0" class="arrow pt-4">mdi-arrow-left-thick</v-icon>
        <v-icon size="50" v-else-if="trend < 0" class="arrow down pt-4">mdi-arrow-left-thick</v-icon>
        <v-icon size="50" v-else class="arrow up pt-4">mdi-arrow-left-thick</v-icon>
        <svg viewBox="0 0 15 30" width="75" height="130">
          <defs>
            <clipPath id="clip">
              <rect
                :transform="'translate(0 ' + (25 - weightSum) + ')'"
                x="0"
                y="-4"
                width="15"
                height="30"
              ></rect>
            </clipPath>
          </defs>
          <g
            stroke="#6385FF"
            fill="none"
            stroke-width="1"
            stroke-linecap="round"
            transform="translate(7.5 0)"
          >
            <path d="M 0 2.5 h 7"></path>
            <path d="M 0 5.5 h 5"></path>
            <path d="M 0 8.5 h 5"></path>
            <path d="M 0 11.5 h 7"></path>
            <path d="M 0 14.5 h 5"></path>
            <path d="M 0 17.5 h 5"></path>
            <path d="M 0 20 h 7"></path>
          </g>
          <g
            clip-path="url(#clip)"
            stroke="#FF6363"
            fill="none"
            stroke-width="1"
            stroke-linecap="round"
            transform="translate(7.5 0)"
          >
            <path d="M 0 2.5 h 7"></path>
            <path d="M 0 5.5 h 5"></path>
            <path d="M 0 8.5 h 5"></path>
            <path d="M 0 11.5 h 7"></path>
            <path d="M 0 14.5 h 5"></path>
            <path d="M 0 17.5 h 5"></path>
            <path d="M 0 20 h 7"></path>
          </g>
          <g>
            <path
              d="M 7.5 2.5 v 20"
              fill="none"
              stroke="#fff"
              stroke-width="5"
              stroke-linecap="round"
            ></path>
            <circle cx="7.5" cy="25" r="5" fill="#fff"></circle>
          </g>
          <path
            d="M 7.5 2.5 v 20"
            fill="none"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            opacity="0.2"
          ></path>
          <g>
            <g clip-path="url(#clip)">
              <path
                d="M 7.5 2.5 v 20"
                fill="none"
                stroke="#FF6363"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </g>
            <circle cx="7.5" cy="25" r="3" fill="#FF6363"></circle>
          </g>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(result, index) in settings"
        :key="result.title"
        :cols="result.flex"
      >
        <v-card>
          <v-card-title class="text-subtitle-1 font-weight-bold" v-text="result.title"></v-card-title>
          <div class="d-flex justify-space-between mx-4">
            <v-icon
              v-for="(option, index) in result.weights" :key="index"
              large            
            >
              $vuetify.icons.{{option.icon}}
            </v-icon>
          </div>
          <v-slider
            thumb-size="50"
            v-model="sliderStatus[index]"
            :max="result.weights.length - 1"
            @change="sliderChanged"
            ticks="always"
            tick-size="0"
            class="mx-5"
          ></v-slider>
        </v-card>
      </v-col>
    </v-row>
    <!--<h2>Matrix Ergebnis: {{ weightSum }}</h2> -->
  </v-container>
</template>

<script>
  export default {
    name: "start",

    data: () => ({
      results: [
        {
          title: "Eisflächen",
          about: [
            {
              sub: "Gut zu wissen",
              p1: "Auf der Erde gibt es große Flächen, die das ganze Jahr von dickem Eis bedeckt sind. Dazu gehören Gletscher, das Grönlandeis, aber auch ganze Kontinente wie die Arktis und die Antarktis. Diese Eisflächen kühlen die Erde, sodass wir Menschen eine gute Temperatur zum Leben haben. Wird es auf der Erde wärmer, schmilzt das Eis. Dadurch werden dunklere Flächen sichtbar, die bislang von hellem Eis bedeckt waren. Dunkle Oberflächen speichern mehr Wärme als helle Oberflächen und dadurch wird die Erde noch wärmer. (Vielleicht hast du selbst schon einmal bemerkt, dass dir bei Sonnenschein in einem schwarzen T-Shirt wärmer wird als in einem weißen T-Shirt.)",
              p2: "Kurz gesagt: Durch höhere Temperaturen schmilzt das Eis auf der Erde.",
            },
            {
              sub: "Wo liegt das Problem?",
              p1: "Wenn diese großen Eisflächen schmelzen, verlieren die Tiere, die dort leben, ihren Lebensraum. Einige Tiere sterben aus, andere finden nicht mehr genug zu essen. Das betrifft beispielsweise auch den Eisbären in der Arktis. Durch die Eisschmelze gibt es weniger sichere Eisschollen, die der Eisbär zum Jagen von Robben benötigt. Das hat zur Folge, dass er weniger Essen für sich und seine Kinder findet. Das Eis und gefrorene Böden sorgen auch für die Festigkeit der Gletscher und Berge in Deutschland und anderen Ländern. Tauen das Eis und die Böden, kommt es immer wieder zu Lawinen und Erdrutschen. So fallen große Mengen Geröll und Schutt talabwärts. Das ist eine Gefahr für Straßen und Menschen.",
              p2: "Kurz gesagt: Der Lebensraum des Eisbären und anderen Tieren ist gefährdet.",
            },
            {
              sub: "Was bedeutet das für mich?",
              p1: "Die Eisschmelze sorgt dafür, dass es auf der Erde sehr schnell wärmer wird. Das klingt erst einmal toll, da wir schon früher im Jahr baden gehen können. Doch nach einigen Jahren wird es uns vor allem im Sommer viel zu heiß werden. Die hohen Temperaturen, die durch die Eisschmelze entstehen, wirken sich aber auch auf den Meeresspiegel, die Tierwelt und die Ernte aus. Lies dort nach, welche Folgen hohe Temperaturen auf der Erde haben.",
              p3: "Überlege, was wir Menschen tun können, um die Eisschmelze zu stoppen. Erkläre deine Idee einer Mitschülerin oder einem Mitschüler.",
            },
          ],
          flex: 3,
          images: [
            {
              src: "/assets/Eisschmelze_Lvl1.svg",
            },
            {
              src: "/assets/Eisschmelze_Lvl2.svg",
            },
            {
              src: "/assets/Eisschmelze_Lvl3.svg",
            },
            {
              src: "/assets/Eisschmelze_Lvl4.svg",
            },
          ],
        },
        {
          title: "Meeresspiegel",
          about: [
            {
              sub: "Gut zu wissen",
              p1: "Der Meeresspiegel gibt an, wie hoch das Wasser in den Meeren steht. Auf der Erde gibt es drei große Eisflächen: die Arktis, die Antarktis und das Grönlandeis. Sie sind von dickem Eis bedeckt. Wird es auf der Erde wärmer, beginnen diese Eisflächen zu schmelzen. Das geschmolzene Eis fließt in die Meere, wodurch der Meeresspiegel ansteigt.",
              p2: "Kurz gesagt: Durch die Eisschmelze erhöht sich der Meeresspiegel.",
            },
            {
              sub: "Wo liegt das Problem?",
              p1: "Durch den Anstieg des Meeresspiegels werden Inseln und Küsten überschwemmt und die Menschen und Tiere, die dort leben, verlieren ihren Lebensraum.",
              p2: "Kurz gesagt: Wenn der Meeresspiegel zu hoch ist, kommt es zu Überschwemmungen.",
            },
            {
              sub: "Was bedeutet das für mich?",
              p1: "Auch in Deutschland verschwinden an der Nordsee und der Ostsee immer mehr Strände durch den erhöhten Meeresspiegel. Außerdem gibt es mehr Sturmfluten mit Überschwemmungen.",
              p2: "Kurz gesagt: Auch Deutschland ist von Überschwemmungen und Sturmfluten betroffen.",
              p3: "Überlege, wie wir Menschen den weiteren Anstieg des Meeresspiegels verhindern können. Erkläre deine Idee einer Mitschülerin oder einem Mitschüler.",
            },
          ],
          flex: 3,
          images: [
            {
              src: "/assets/Meeresspiegel_Lvl1.svg",
            },
            {
              src: "/assets/Meeresspiegel_Lvl2.svg",
            },
            {
              src: "/assets/Meeresspiegel_Lvl3.svg",
            },
            {
              src: "/assets/Meeresspiegel_Lvl4.svg",
            },
          ],
        },
        {
          title: "Ernte",
          about: [
            {
              sub: "Gut zu wissen",
              p1: "Wird es auf der Erde immer wärmer, gibt es auch mehr Wetterextreme wie Trockenheit, Hitze, starken Regen und Überschwemmungen. Pflanzen können vertrocknen oder überschwemmt werden. Getreidearten und Gemüsearten reagieren unterschiedlich auf den Klimawandel.",
              p2: "Kurz gesagt: Der Klimawandel führt in vielen Gebieten zu weniger Ernte.",
            },
            {
              sub: "Wo liegt das Problem?",
              p1: "Südliche Gebiete, wie zum Beispiel Länder in Afrika, trifft die Trockenheit besonders hart. Dort regnet es immer weniger, sodass nicht ausreichend Wasser für den Ackerbau vorhanden ist. Es wird zu Ernteausfällen kommen. Dadurch werden die Menschen in manchen Gebieten nicht mehr genug Essen haben. In Europa wirkt sich der Klimawandel unterschiedlich auf die Ernte aus. Durch die hohen Temperaturen können mehr Insekten überleben und die Pflanzen schädigen. Auch Extremwetter wie plötzlicher Frost oder Hagel kann für weniger Ernte sorgen.",
              p2: "Kurz gesagt: Durch den Klimawandel müssen in vielen Regionen immer mehr Menschen hungern.",
            },
            {
              sub: "Was bedeutet das für mich?",
              p1: "Manche Pflanzen werden in Deutschland nicht mehr angebaut werden können, weil es ihnen zu trocken und zu heiß wird. Das betrifft zum Beispiel den Mais. Der Weizen allerdings kommt mit hohen Temperaturen und mehr CO2 gut zurecht. Außerdem kann der Weizen durch die zunehmende Wärme sogar in bisher kälteren Gebieten im Norden von Europa angebaut werden. Das war früher nicht möglich. Pflanzen werden durch die wärmeren Temperaturen schneller wachsen. Durch das schnelle Wachstum werden sie aber weniger wichtige Nährstoffe für uns Menschen haben. Auch das betrifft den Weizen. Doch in ungefähr 50 Jahren wird es auch dem Weizen bei uns zu heiß und die Ernte wird weniger werden.",
              p2: "Kurz gesagt: Durch den Klimawandel wird sich unsere Ernährung in Zukunft verändern.",
              p3: "Überlege, was wir Menschen tun können, um auch in Zukunft genug zu essen zu haben. Erkläre deine Idee einer Mitschülerin oder einem Mitschüler.",
            },
          ],
          flex: 3,
          images: [
            {
              src: "/assets/Ernte_Lvl1.svg",
            },
            {
              src: "/assets/Ernte_Lvl2.svg",
            },
            {
              src: "/assets/Ernte_Lvl3.svg",
            },
            {
              src: "/assets/Ernte_Lvl4.svg",
            },
          ],
        },
        {
          title: "Tierwelt",
          about: [
            {
              sub: "Gut zu wissen",
              p1: "Wenn die Temperatur auf der Erde steigt, verändert sich das Leben vieler Tiere. Die Hitze und die Trockenheit machen vielen Tieren zu schaffen. Manche Tierarten können sich anpassen, andere müssen ihren Lebensraum verlassen und in kältere Regionen fliehen. Nicht alle Tierarten können sich an das wärmer werdende Klima anpassen. Sie werden früher oder später aussterben.",
              p2: "Kurz gesagt: Viele Tiere verlieren durch den Klimawandel ihren Lebensraum.",
            },
            {
              sub: "Wo liegt das Problem?",
              p1: "Jedes Jahr ziehen viele Meerestiere weiter in kälteres Gewässer Richtung Nordpol und Südpol. Insekten wie Schmetterlinge ziehen die Berge hinauf, wo die Lufttemperatur kälter ist. Der Koala in Australien leidet ebenfalls unter dem Klimawandel. Die starke Hitze löst Waldbrände aus, die seinen Lebensraum bedrohen. Durch die Trockenheit wächst weniger Eukalyptus und der Koala hat weniger zu essen. Auch der Eisbär kann den hohen Temperaturen nicht ausweichen. Sein Lebensraum, das Eis, wird kleiner, da es schmilzt. Er wird irgendwann aussterben, wenn sich die Temperatur auf der Erde weiter erhöht.",
              p2: "Kurz gesagt: Tiere müssen in kältere Gebiete fliehen oder sterben irgendwann aus.",
            },
            {
              sub: "Was bedeutet das für mich?",
              p1: "Die Tierwelt in Deutschland wird sich wegen den hohen Temperaturen verändern. Der Waldbaumläufer zum Beispiel kann sich nicht an die Wärme anpassen. Es wird in Zukunft weniger Vögel dieser Art geben. Es gibt jedoch auch Vogelarten, die sich gut an die Wärme anpassen können, zum Beispiel der Buntspecht. Er wird sich in Deutschland weiter ausbreiten. Dadurch, dass es in Deutschland wärmer wird, kommen aber auch Insekten nach Deutschland, denen es hier bisher zu kalt war. Ein Beispiel dafür ist die asiatische Tigermücke, die Krankheiten auslösen kann.",
              p2: "Kurz gesagt: Durch den Klimawandel wird sich die Tierwelt in Deutschland verändern.",
              p3: "Überlege, was wir Menschen tun können, damit die Tiere (wie der Waldbaumläufer) in Deutschland weiterleben können. Erkläre deine Idee einer Mitschülerin oder einem Mitschüler.",
            },
          ],
          flex: 3,
          images: [
            {
              src: "/assets/Tierwelt_Lvl1.svg",
            },
            {
              src: "/assets/Tierwelt_Lvl2.svg",
            },
            {
              src: "/assets/Tierwelt_Lvl3.svg",
            },
            {
              src: "/assets/Tierwelt_Lvl4.svg",
            },
          ],
        },
      ],
      settings: [
        {
          title: "Energieverbrauch",
          flex: 4,
          weights: [
            {
              icon: "house_candle",
              weight: 0,
            },
            {
              icon: "house_light",
              weight: 1,
            },
            {
              icon: "house_lights",
              weight: 2,
            },
            {
              icon: "house_open",
              weight: 3,
            },
          ],
        },
        {
          title: "Fortbewegung",
          flex: 4,
          weights: [
            {
              icon: "car",
              weight: 3,
            },
            {
              icon: "train",
              weight: 1,
            },
            {
              icon: "bike",
              weight: 0,
            },
            {
              icon: "flight",
              weight: 4,
            },
          ],
        },
        {
          title: "Ernährung",
          flex: 4,
          weights: [
            {
              icon: "vegetables",
              weight: 0,
            },
            {
              icon: "xl_vegetables_meat",
              weight: 2,
            },
            {
              icon: "vegetables_meat",
              weight: 3,
            },
            {
              icon: "meat",
              weight: 4,
            },
          ],
        },
        {
          title: "Stromerzeugung",
          flex: 4,
          weights: [
            {
              icon: "solar_energy",
              weight: 0,
            },
            {
              icon: "wind",
              weight: 0,
            },
            {
              icon: "water",
              weight: 0,
            },
            {
              icon: "coal",
              weight: 4,
            },
          ],
        },
        {
          title: "Freizeit",
          flex: 4,
          weights: [
            {
              icon: "gaming",
              weight: 2,
            },
            {
              icon: "swim",
              weight: 2,
            },
            {
              icon: "outdoor",
              weight: 0,
            },
            {
              icon: "reading",
              weight: 1,
            },
          ],
        },
        {
          title: "Verpackungen",
          flex: 4,
          weights: [
            {
              icon: "carton",
              weight: 1,
            },
            {
              icon: "glass",
              weight: 1,
            },
            {
              icon: "bag",
              weight: 0,
            },
            {
              icon: "plastic",
              weight: 3,
            },
          ],
        },
      ],

      thresholds: {
        small: [0, 7, 14, 100],
        large: [0, 5, 10, 15],
      },

      timeTravels: {
        none: 0,
        small: 10,
        large: 100,
      },

      currentTimeTravel: "none",

      sliderStatus: [],

      ecosystem: [
        {
          text: "vuetify-loader",
          href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
          text: "github",
          href: "https://github.com/vuetifyjs/vuetify",
        },
        {
          text: "awesome-vuetify",
          href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
      ],

      trend: 0,
      oldWeight: undefined,
    }),

    mounted() {
      this.oldWeight = this.weightSum;
    },

    computed: {
      weightSum() {
        return this.sliderStatus.reduce(
          (acc, current, index) =>
            acc + this.settings[index].weights[current].weight,
          0
        );
      },
      currentThreshold() {
        let lastIndex = 0;
        const currentWeight = this.weightSum;
        this.thresholds[this.currentTimeTravel]?.forEach((element, index) => {
          if (currentWeight >= element) {
            lastIndex = index;
          }
        });
        return lastIndex;
      },
      timeTravelList() {
        /*let year = new Date().getFullYear(); Wenn man die Jahre basierend auf dem aktuellen Datum ausrechnen möchte*/
        return Object.entries(this.timeTravels).map(([key, element]) => {
          return {
            text:
              element > 10
                ? "In 100 Jahren"
                : element > 0
                ? "In 10 Jahren"
                : "Heute",
            value: key,
          };
        });
      },
      thermoStyle() {
        return { transform: `translate(0 ${this.weightSum})` };
      },
    },

    watch: {
      sliderStatus() {
        if (this.oldWeight !== undefined) {
          this.trend = this.weightSum - this.oldWeight;
        }
        this.oldWeight = this.weightSum;
      },
    },
  };
</script>
<style scoped>
  svg #clip rect {
    transition: transform 0.5s;
  }
  .theme--light.v-card > .v-card__text,
  .theme--light.v-card > .v-card__subtitle,
  .theme--light.v-image {
    color: #333333;
  }
  .theme--light.v-card {
    color: #545f66;
  }
    
  .v-card__subtitle,
  .v-card__text {
    font-size: inherit;
    line-height: inherit;
  }
  .v-dialog > .v-card > .v-card__title {
    font-size: 1.5rem;
    font-weight: 700;
    background-color: #e5e5e5;
    color: #333333;
  }
  .v-icon.v-icon.arrow {
    align-items: flex-start;
  }
  .arrow:before {
    transition: 0.8s all cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  .down:before {
    transform: rotateZ(-90deg) ;
  }
  .up:before {
    transform: rotateZ(90deg) ; 
  }

</style>
<style>
.theme--light.v-application {
  background: linear-gradient(#add2ed 30%, #a8d2a1 50%) !important;
}
.v-slider__thumb {
    width: 20px !important;
    height: 20px !important;
}
.v-slider--horizontal .v-slider__track-container {
  height: 3px !important;
}
</style>